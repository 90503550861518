<template>
    <div>
        <div v-if="!hasAccess()">
            <div class="alert alert-warning">Недоступно</div>
        </div>
        <template v-else>
            <div v-if="loading" class="alert alert-info text-center">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
            <table v-else class="table">
                <tbody>
                <tr v-for="(restriction, index) in restrictions">
                    <td>
                        <button class="btn btn-sm btn-danger"
                                @click="deleteRestriction(restriction, index)"
                                title="Удалить">
                            <i v-if="beingDeleted(restriction)" class="fas fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-times"></i>
                        </button>
                    </td>
                    <td>Начиная с <b>{{restriction.date}}</b> не обклеивать приемы: </td>
                    <td v-for="i in maxMealDays">
                        <b-checkbox :disabled="true" :checked="findIndex(restriction.items, item => item.meal_day === i) !== -1">{{i}}</b-checkbox>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td>
                        <button @click="save()" class="btn btn-sm btn-success">
                            <i v-if="saving" class="fas fa-spinner fa-spin"></i>
                            <i v-else class="fas fa-plus"></i>
                        </button></td>
                    <td>Начиная с <datetime :type="'date'"
                                            :disabled="saving"
                                            class="no-border"
                                            style="display: inline-block"
                                            v-model="date"></datetime> не обклеивать приемы:</td>
                    <td v-for="i in maxMealDays">
                        <b-checkbox @change="selectMealDay(i, $event)"
                                    :disabled="saving"
                                    :selected="mealDaySelected(i)">{{i}}</b-checkbox>
                    </td>
                </tr>
                </tfoot>
            </table>
        </template>
    </div>
</template>

<script>
    import moment from 'moment';
    import {findIndex} from 'lodash';
    import {Datetime} from 'vue-datetime';
    import {LABELING_RESTRICTIONS_ENDPOINT} from "../../utils/endpoints";

    const ALLOWED_USERS = [
        'test@test.ru',
        'v.fedyanin@growfood.pro'
    ];
    const WITH = [
        'items'
    ];

    export default {
        name: "LabelingRestriction",
        components: {
            datetime: Datetime,
        },
        data() {
            return {
                maxMealDays: 5,
                restrictions: [],
                loading: false,

                date: null,
                mealDays: [],
                saving: false,
            };
        },
        methods: {
            moment,
            findIndex,
            hasAccess() {
                return ALLOWED_USERS.some(n => n===this.$auth.user().username);
            },
            load() {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.$http.get(LABELING_RESTRICTIONS_ENDPOINT, {
                    params: {
                        without_loading: true,
                        sort: 'date',
                        sortDirection: 'asc',
                        with: WITH
                    }
                }).then(response => {
                    this.restrictions = response.data.data.data;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.loading = false;
                })
            },
            selectMealDay(mealDay, value) {
                let index = findIndex(this.mealDays, d => d===mealDay);
                if (index !== -1) {
                    this.mealDays.splice(index, 1);
                }

                if (value) {
                    this.mealDays.push(mealDay);
                }
            },
            mealDaySelected(mealDay) {
                return findIndex(this.mealDays, d => d===mealDay) !== -1;
            },
            save() {
                if (this.saving) {
                    return;
                }
                if (!this.date) {
                    this.$toast.error('Не указана дата');
                    return;
                }
                if (this.mealDays.length === 0) {
                    this.$toast.error('Не выбраны приемы');
                    return;
                }
                this.saving = true;

                this.$http.post(LABELING_RESTRICTIONS_ENDPOINT, {
                    date: moment(this.date).format('YYYY-MM-DD'),
                    mealDays: this.mealDays,
                    with: WITH,
                }, {params: {without_loading: true}}).then(response => {
                    this.restrictions.push(response.data);
                    this.date = null;
                }).catch(response => {
                    this.$toast.error(response.data.message);
                }).finally(() => {
                    this.saving = false;
                });
            },
            deleteRestriction(restriction, index) {
                if (this.beingDeleted(restriction)) {
                    return;
                }
                restriction.beingDeleted = true;
                this.$forceUpdate();
                this.$http.delete(LABELING_RESTRICTIONS_ENDPOINT + `/${restriction.id}`, {params: {without_loading: true}})
                    .then(response => {
                        this.restrictions.splice(index, 1);
                    }).catch(response => {
                        this.$toast.error(response.data.message);
                    }).finally(() => {
                        restriction.beingDeleted = false;
                    });
            },
            beingDeleted(restriction) {
                return !!restriction.beingDeleted;
            }
        },
        mounted() {
            if (this.hasAccess()) {
                this.load();
            }
        }
    }
</script>

<style>
    .no-border > input {
        border: none;
    }
</style>
